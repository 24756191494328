<template>
  <Hero
    heroTitle="The right job can change everything"
    heroDesc="At CareerBuddy, we utilize a unique combination of matching technology and human empathy to connect you with the right opportunity."
    heroBtnText="Find your Next Job"
    heroImg="candidate-top.png"
    :heroBtnLink="$candidateUrl"
  />

  <div class="flex justify-center bg-[#FEF7F1]">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-8">
        <h2 class="mb-4 text-center text-[14px] md:text-[28px] font-semibold">Companies where our talents work</h2>
        <div class="flex flex-wrap items-center justify-center xl:justify-between rounded-lg mb-4">
          <img src="@/assets/images/airbnb-logo.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
          <img src="@/assets/images/first-bank-logo.svg" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
          <img src="@/assets/images/piggy-png.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
          <img src="@/assets/images/paystack-logo.svg" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
          <img src="@/assets/images/target-logo.png" class="mr-3 mb-3 md:mb-0 w-[18px] md:w-auto" alt="" />
          <img src="@/assets/images/Flutterwave-logo.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
          <img src="@/assets/images/apt.png" class="mb-3 md:mb-0 w-[60px] md:w-auto" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-[#1A1A1A] w-full">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-3 text-[32px] md:text-[60px] text-white leading-[110%] font-semibold">How do we serve you?</h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5 bg-[#1A1A1A]">
          <div class="mb-4 md:mb-0 bg-brand-white p-8 bg-white">
            <div class="h-8 bg-[#227B9B] rounded-sm mb-9"></div>
            <span class="text-[#FE6D74]">01</span>
            <h3 class="font-bold text-3xl mb-4 mt-10">Create a profile. Get great matches</h3>
            <p>
              Tell us about your background and what ou look for in a job. It takes a few steps, but t will make sure our matching algorithms find
              jobs that fit your skills and preferences. We’ve even got a resume uploader to make thing faster.
            </p>
            <ul class="list text-[#FE6D74] mt-5">
              <li>Showcase your skill set, passions and career options</li>
              <li>
                We know you are more than your resume, so don’t be afraid to put yourself out there. Customise your profile with photos of your
                favourite projects, links to personal work, or glowing reviews.
              </li>
              <li>Your profile is always hidden from past and current employers</li>
            </ul>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-white p-8 bg-white">
            <div class="h-8 bg-[#227B9B] rounded-sm mb-9"></div>
            <span class="text-[#00920F]">02</span>
            <h3 class="f font-bold text-3xl mb-4 mt-10">Companies apply to you</h3>
            <p>
              Once you’re approved, your profile ill be prompted to employers for at least two weeks. When our platform sees a potential fit, that
              employer will see you in their matches and can request an interview
            </p>
            <ul class="list text-[#00920F] mt-5">
              <li>No applications, no cover letters</li>
              <li>When companies reaches out to you, they will ome with a job description and salary upfront</li>
              <li>You will hear from employers that match well with you.</li>
            </ul>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-white p-8 bg-white">
            <div class="h-8 bg-[#227B9B] rounded-sm mb-9"></div>
            <span class="text-[#4255FF]">03</span>
            <h3 class="font-bold text-3xl mb-4 mt-10">Choose, interview and accept.</h3>
            <p>
              Tell us about your background and what ou look for in a job. It takes a few steps, but t will make sure ur matching algorithms find jobs
              that fit your skills and preferences. We’ve even got a resume uploader to make thing faster.
            </p>
            <ul class="list text-[#4255FF] mt-5">
              <li>Showcase your skill set, passions and career options</li>
              <li>
                We know you are more than your resume, so don’t be afraid to put yourself out there. Customise your profile with photos of your
                favourite projects, links to personal work, or glowing reviews.
              </li>
              <li>Your profile is always hidden from past and current employers.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <div class="block md:flex items-center justify-between gap-16 pb-5 bg-white">
          <div class="w-full md:w-1/2">
            <img src="@/assets/images/man-plus.png" class="mx-auto md:mx-0" alt="" />
          </div>

          <div class="w-full md:w-1/2 ml-0 md:ml-16">
            <h3 class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block font-semibold">
              Effectively Track real-time and update from team management.
            </h3>

            <p class="text-brand-black mb-8 font-light">
              Once you’re accepted to CareerBuddy, companies reach out to directly to you. Our matching algorithm shows off our profile to hiring
              managers looking for someone with your skills, experience and preference.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex bg-light-yellow justify-center">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <div class="mb-8">
          <h3 class="text-[32px] md:text-[60px] leading-[110%] mb-4 font-semibold text-brand-black">Join a Verified Talentpool</h3>
          <p class="text-[17px] md:text-[21px] text-black/50">
            Most hiring takes time and it’s stressful. Join our Verified pool and get presented to multiple employers at once. The verification
            process is rigorous to make sure everyone accepted gets the best opportunities faster. We will promote your profle weekly to employers
            hiring.
          </p>
        </div>

        <div class="md:grid md:grid-cols-2 gap-10">
          <div class="p-8 rounded-2xl bg-white text-center mb-6">
            <h3 class="text-[18px] md:text-[32px] mb-3 leading-[110%] text-[#292C26]">Refer friends to career buddy</h3>
            <p class="text-[14px] md:text-[18px] font-light leading-[130%] md:leading-[110%] text-[#292C26] mb-8">
              Help your friends and yourself. We’ll send you a littel extra cash for each friend who lands a job or makes a hire through us
            </p>
            <div class="text-center mb-8">
              <a href="" class="inline-block rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Start Referring</a>
            </div>
          </div>

          <div class="p-8 rounded-2xl bg-white text-center mb-8 md:mb-0">
            <h3 class="text-[18px] md:text-[32px] mb-3 leading-[110%] text-[#292C26]">Get ready for greatness</h3>
            <p class="text-[14px] md:text-[18px] font-light leading-[130%] md:leading-[110%] text-[#292C26] mb-8">
              Check out the CareerBuddy blog and our newsletters for tips, insights and stories around the world of work. Plus information on some of
              your favourite companies
            </p>
            <div class="text-center">
              <a href="https://blog.thecareerbuddy.com/" class="inline-block rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Read our Blog</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-[#1A1A1A] w-full">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="text-center text-[32px] md:text-[60px] text-white leading-[110%] font-semibold mb-8">Hear what others are saying</h2>

        <div class="px-8 mb-8">
          <div class="h-[2px] bg-white"></div>
        </div>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 pb-5">
          <div class="bg-white rounded-2xl p-8 mb-3 md:0">
            <p class="text-brand-black font-light mb-6">
              CareerBuddy made the job hunting process so much easier for me. Their team was very responsive and kept me updated on any job
              opportunities that matched my skills and interests. They connected me with a startup that was the perfect fit and now I&#039;m working
              in my dream job. Thank you, CareerBuddy!
            </p>

            <div class="flex items-center mb-4">
              <h3 class="font-medium text-[20px] pt-2">Damola</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8 mb-3 md:0">
            <p class="text-brand-black font-light mb-6">
              CareerBuddy helped me land a job with one of the best startups in Africa. Their team was extremely helpful throughout the entire
              process, from polishing my resume to preparing me for interviews. They were always available to answer any questions I had and made the
              entire process stress-free.
            </p>

            <div class="flex items-center mb-4">
              <h3 class="font-medium text-[20px] pt-2">Bolanle</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8">
            <p class="text-brand-black font-light mb-6">
              I couldn't be happier with my experience with CareerBuddy. They connected me with a great startup that was a fit for my skills and
              interests. Their team provided me with valuable insights and guidance throughout the hiring process, making everything seamless. I'm
              grateful for their support and expertise
            </p>

            <div class="flex items-center mb-4">
              <h3 class="font-medium text-[20px] pt-2">Nkiru</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-brand-black text-center">
        <h1 class="text-brand-black text-[32px] md:text-[60px]">Use our CV Writing Business</h1>

        <p class="text-[18px] md:text-[32px] leading-[110%] mb-[65px] text-brand-black/50">
          Use our CV Writing service to increase your chances of landing your <br class="hidden lg:inline-block" />
          next job, Speak to a career coach to help you find your next <br class="hidden lg:inline-block" />
          opportunity faster.
        </p>
        <br />
        <div>
          <router-link to="cv" class="rounded-2xl px-8 md:px-16 py-4 text-center bg-brand-black text-white text-[15px] md:text-[18px]"
            >Get your new CV</router-link
          >
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Hero from '@/components/partials/Hero.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>
